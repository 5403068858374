.sign_in {
  background: rgba(19, 19, 24, 0.48);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media (min-width: 767px) {
    justify-content: center;
    align-items: center;
  }

  &.closing {
    .sign_in_body {
      animation: slideDown 0.5s ease-in-out forwards;
    }
  }
}

.sign_in_body {
  border-top: 1px solid #202028;
  border-radius: 16px 16px 0 0;
  padding: 16px 16px 24px 16px;
  width: 100%;
  max-width: 360px;
  background: #131318;
  animation: slideUp 0.5s ease-in-out forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.sing_in_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.sing_in_close {
  cursor: pointer;
}

.steam_btn {
  border-radius: 8px !important;
  min-height: 48px;
  position: relative;
  margin-bottom: 8px;

  svg {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.sign_in_devider {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  gap: 8px;
  margin-bottom: 8px;

  span {
    flex: 1;
    height: 1px;
    background-color: #363744;
  }
}

.sign_in_socials {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.sign_in_socials_btn {
  width: 48px !important;
  height: 48px !important;
  border-radius: 8px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vk_btn {
  background-color: #1fa8ff !important;
}

.fb_btn {
  background-color: #0866ff !important;
}

.google_btn {
  background-color: #ffffff !important;
}

.sign_in_description {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #47485d;
}
