.mobile_language_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  padding-top: 32px;

  @media (min-width: 767px) {
    justify-content: center;
    align-items: center;
  }
}

.mobile_language_modal_content {
  background: #131318;
  padding: 16px 16px 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  border-radius: 16px 16px 0 0;
  animation: slideRight 0.5s ease-in-out forwards;

  @media (min-width: 767px) {
    max-width: 400px;
    height: fit-content;
    border-radius: 16px;
  }

  &.closing {
    animation: slideLeft 0.5s ease-in-out forwards;
  }
}

@keyframes slideRight {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideLeft {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.mobile_language_modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.mobile_language_modal_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
}

.mobile_language_modal_close {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  top: 12px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
}

.mobile_language_modal_languages {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mobile_language_modal_language {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px solid transparent;

  &_active {
    background: #363744;
    border-color: #202028;
  }

  img {
    border-radius: 50%;
    object-fit: cover;
  }
}

.mobile_language_modal_language_name {
  margin-left: 12px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  flex-grow: 1;
}

.mobile_language_modal_language_check {
  color: #ffffff;
  font-weight: bold;
}
