@use "../../../scss/styles/colors" as *;

.profile_items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 32px;

  @media (max-width: 1024px) {
    gap: 16px;
    margin-bottom: 16px;
  }
}

.profile_items_button {
  min-width: 328px;
  border-radius: 8px;

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.profile_items_grid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}

.profile_item {
  position: relative;
  width: calc((100% - 48px) / 7);
  background: $top_drop_item_classified;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 4px;
  max-height: 164px;

  @media (max-width: 1440px) {
    width: calc((100% - 40px) / 6);
  }

  @media (max-width: 1024px) {
    width: calc((100% - 24px) / 4);
  }

  @media (max-width: 768px) {
    width: calc((100% - 8px) / 2);
    height: 132px;
  }

  // Разные стили для разных редкостей
  &_consumer {
    background: $top_drop_item_consumer;
    color: $rarity_consumer;
  }

  &_military {
    background: $top_drop_item_military;
    color: $rarity_military;
  }

  &_industrial {
    background: $top_drop_item_industrial;
    color: $rarity_industrial;
  }

  &_restricted {
    background: $top_drop_item_restricted;
    color: $rarity_restricted;
  }

  &_classified {
    background: $top_drop_item_classified;
    color: $rarity_classified;
  }

  &_covert {
    background: $top_drop_item_covert;
    color: $rarity_covert;
  }

  &_legendary {
    background: $top_drop_item_legendary;
    color: $rarity_legendary;
  }

  &_foreign {
    opacity: 0.48;
    pointer-events: none;
  }
}

.item_percent {
  position: absolute;
  top: 4px;
  right: 4px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  background: rgba(19, 19, 24, 0.5);
  color: #ffffff;
  z-index: 2;
  height: 18px;
  display: flex;
  align-items: center;
  padding: 1px 4px;

  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 10px;
    line-height: 120%;
    padding: 0 4px;
    height: 16px;
  }
}

.item_image_container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  width: 144px;
  height: 108px;
  margin: 12px auto -40px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 767px) {
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 107px;
    max-height: 80px;
  }
}

.item_button_container {
  display: flex;
  gap: 4px;
  width: 100%;
}

.item_image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.item_info {
  margin-bottom: 12px;

  @media (max-width: 767px) {
    margin-top: auto;
    margin-bottom: 4px;
  }
}

.item_name {
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  margin-bottom: 4px;
  opacity: 0.64;
  color: #ffffff;

  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 10px;
    line-height: 140%;
    margin-bottom: 2px;
  }
}

.item_skin {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding-right: 24px;

  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
  }

  span {
    color: #ffb800;
  }
}

.item_button {
  width: 100%;
  display: flex;
  gap: 4px;

  a,
  button {
    width: 100%;
    height: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    border-radius: 4px;
    min-width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
  }

  .btn_green,
  .btn_dark,
  .btn_blue {
    flex-grow: 1;
    width: 100%;
  }

  .btn_icon {
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    width: auto;
    max-width: 32px;
  }
}

.item_price {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  background: rgba(19, 19, 24, 0.5);
  position: absolute;
  left: 4px;
  top: 4px;
  border-radius: 4px;
  color: #ffffff;
  padding: 1px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  .price_icon {
    flex-shrink: 0;
    margin-left: -3px;
  }

  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 10px;
    line-height: 120%;
  }
}

.profile_load_more {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
}

.profile_load_more_btn {
  min-width: 145px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
}
