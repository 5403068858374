.profile_stats {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 64px;
  padding-bottom: 40px;
  border-bottom: 2px solid #202028;
  margin-bottom: 16px;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 24px;
  }

  &_left {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 328px;
    border-radius: 8px;
    background: #202028;
    padding: 8px;

    @media (max-width: 1024px) {
      max-width: 100%;
    }
  }

  &_center {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &_right {
    padding: 12px;
    background-color: #202028;
    border-radius: 8px;

    @media (max-width: 1024px) {
      max-width: 100%;
      width: 100%;
    }
  }

  // Общие стили для кнопок в компоненте
  &_button {
    padding: 4px 12px 4px 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: center;

    &_deposit {
      color: #482000;
    }
  }
}

.balance_block {
  display: flex;
  align-items: center;
  padding: 4px 0;

  .balance_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }

  .balance_amount {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #fff;
    margin-right: auto;
    padding-top: 4px;
  }
}

.balance_devider {
  margin: 4px 0;
  height: 2px;
  width: 100%;
  background: #363744;
}

.stats_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  margin-bottom: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  span {
    padding-top: 4px;
  }

  @media (max-width: 1024px) {
    margin-bottom: 0;
    cursor: pointer;
    justify-content: space-between;

    &_active {
      .stats_title_arrow {
        transform: rotate(180deg);
      }
    }

    &:focus,
    &:active {
      outline: none;
      background-color: transparent;
    }
  }

  &_arrow {
    display: none;
    transition: transform 0.3s ease;

    @media (max-width: 1024px) {
      display: block;
      margin-left: auto;
    }
  }
}

.stats_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px 16px;

  @media (max-width: 1024px) {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
    padding: 0;
    opacity: 0;

    &_visible {
      max-height: 200px;
      overflow: visible;
      padding-top: 16px;
      opacity: 1;
    }
  }
}

.stats_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  .stats_label {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #8a8ca6;
    min-width: 110px;
  }

  .stats_value {
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.profile_info_mobile {
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
  }
}

.profile_info_mobile_descr {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.profile_info_mobile_id {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: #6c6f8b;
  display: flex;
  align-items: center;
  gap: 4px;
}

.profile_info_mobile_exit {
  background: transparent !important;
  margin-left: auto;
}
