.modal1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  backdrop-filter: blur(8px);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;

  @media (max-width: 767px) {
    align-items: flex-end;
  }
}

.modal_body {
  width: 100%;
  max-width: 824px;
  border-radius: 16px;
  padding: 0;
  border: 2px solid #202028;
  background: #131318;
  max-height: calc(100dvh - 24px);

  @media (max-width: 1024px) {
    max-width: 460px;
  }

  @media (max-width: 767px) {
    border-radius: 16px 16px 0 0;
    animation: slideUp 0.5s ease-in-out forwards;

    &.closing {
      animation: slideDown 0.5s ease-in-out forwards;
    }
  }

  @media (min-width: 768px) {
    animation: fadeIn 0.5s ease-in-out forwards;

    &.closing {
      animation: fadeOut 0.5s ease-in-out forwards;
    }
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 83%;
    color: #ffffff;
  }

  &.align_center {
    flex-direction: column;

    h2 {
      text-align: center;
      margin-bottom: 12px;
    }

    .modal_close {
      align-self: flex-end;
      margin-top: -40px;
    }
  }
}

.modal_close,
.modal_close_only {
  cursor: pointer;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #131318;

  &:hover {
    opacity: 0.8;
  }
}

.modal_close_only {
  margin-left: auto;
  position: absolute;
  right: 16px;
  top: 16px;
  background-color: #131318;
}

.modal_content {
  width: 100%;
  max-height: calc(100dvh - 112px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #202028;
    border-radius: 5px;
    border: 2px solid rgb(54, 55, 68, 0.64);
  }

  &::-webkit-scrollbar-thumb {
    background: #363744;
    border-radius: 4px;
    border: 5px solid transparent;
    background-clip: padding-box;
  }
}

.columns_container {
  display: flex;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
}

.left_column,
.right_column {
  flex: 1;
  padding: 24px;
  width: 50%;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.left_column {
  border-right: 1px solid #202028;
}

.right_column {
  border-left: 1px solid #202028;
}

.draw_hash {
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 2px solid #202028;
}

.draw_hash_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #b3b5c6;
}

.draw_hash_value {
  text-wrap: wrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  word-wrap: break-word;
  color: #47485d;
  text-align: left;
}

.draw_history_title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #b3b5c6;
}

.draw_history_item {
  display: flex;
  align-items: center;
  gap: 20px;
}

.draw_histroy_number {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: right;
  color: #b3b5c6;
}

.draw_history_drop {
  border-radius: 8px;
  width: 110px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 6px;
    border-radius: 20px;
    top: -4px;
    width: calc(100% - 16px);
  }

  &_consumer {
    background: linear-gradient(
        180deg,
        rgba(176, 195, 217, 0.3) 0%,
        rgba(211, 221, 234, 0.3) 100%
      ),
      radial-gradient(
        50% 100% at 43.55% 0%,
        rgba(176, 195, 217, 0.48) 0%,
        rgba(176, 195, 217, 0) 100%
      );

    &:before {
      background-color: #b0c3d9;
    }
  }

  &_military {
    background: linear-gradient(
        180deg,
        rgba(75, 105, 255, 0.3) 0%,
        rgba(117, 150, 255, 0.3) 100%
      ),
      radial-gradient(
        50% 100% at 43.55% 0%,
        rgba(75, 105, 255, 0.48) 0%,
        rgba(75, 105, 255, 0) 100%
      );

    &:before {
      background-color: #4b69ff;
    }
  }

  &_industrial {
    background: linear-gradient(
        180deg,
        rgba(94, 152, 217, 0.3) 0%,
        rgba(121, 178, 225, 0.3) 100%
      ),
      radial-gradient(
        50% 100% at 43.55% 0%,
        rgba(94, 152, 217, 0.48) 0%,
        rgba(94, 152, 217, 0) 100%
      );
    &:before {
      background-color: #5e98d9;
    }
  }

  &_restricted {
    background: linear-gradient(
        180deg,
        rgba(136, 71, 255, 0.3) 0%,
        rgba(170, 133, 255, 0.3) 100%
      ),
      radial-gradient(
        50% 100% at 43.55% 0%,
        rgba(136, 71, 255, 0.48) 0%,
        rgba(136, 71, 255, 0) 100%
      );

    &:before {
      background-color: #8847ff;
    }
  }

  &_classified {
    background: linear-gradient(
        180deg,
        rgba(211, 44, 230, 0.3) 0%,
        rgba(226, 59, 250, 0.3) 100%
      ),
      radial-gradient(
        50% 100% at 43.55% 0%,
        rgba(211, 44, 230, 0.48) 0%,
        rgba(211, 44, 230, 0) 100%
      );

    &:before {
      background-color: #d32ce6;
    }
  }

  &_covert {
    background: linear-gradient(
        180deg,
        rgba(235, 75, 75, 0.3) 0%,
        rgba(245, 116, 116, 0.3) 100%
      ),
      radial-gradient(
        50% 100% at 43.55% 0%,
        rgba(235, 75, 75, 0.48) 0%,
        rgba(235, 75, 75, 0) 100%
      );

    &:before {
      background-color: #eb4b4b;
    }
  }

  &_legendary {
    background: linear-gradient(
        180deg,
        rgba(255, 198, 0, 0.3) 0%,
        rgba(255, 229, 27, 0.3) 100%
      ),
      radial-gradient(
        50% 100% at 43.55% 0%,
        rgba(255, 198, 0, 0.48) 0%,
        rgba(255, 198, 0, 0) 100%
      );

    &:before {
      background-color: #ffc600;
    }
  }
}

.draw_histroy_ava {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 400;
  font-size: 16px;
  color: #b3b5c6;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 90px;

    @media (max-width: 1024px) {
      max-width: 72px;
    }
  }
}

.draw_history_scroll {
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
  max-height: 420px;

  @media (max-width: 1024px) {
    max-height: none;
    overflow-y: auto;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #202028;
    border-radius: 5px;
    border: 2px solid rgb(54, 55, 68, 0.64);
  }

  &::-webkit-scrollbar-thumb {
    background: #363744;
    border-radius: 4px;
    border: 5px solid transparent;
    background-clip: padding-box;
  }
}

.right_column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.draw_everyday {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #b3b5c6;
}

.draw_title {
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
  text-transform: uppercase;
  text-align: center;
  color: #ffb800;
  margin-bottom: 16px;
}

.draw_timer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-bottom: 1px solid #202028;
  border-top: 1px solid #202028;
  padding: 8px 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 117%;
}

.draw_donate {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #eb4b4b;
}

.draw_balance {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffb800;

  span {
    color: #ffffff;
  }
}

.draw_quest {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
}

.draw_quest_item {
  padding-left: 24px;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6c6f8b;
  padding: 16px 0 16px 24px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 10px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #6c6f8b;
    //top: 26px;
  }

  span {
    color: #ffffff;
  }

  &_disabled {
    opacity: 0.5;
  }

  &_iconed {
    display: flex;
    align-items: center;
    //gap: 16px;
    gap: 27%;
  }
}

.draw_buttons {
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.draw_users {
  position: absolute;
  z-index: 5;
  padding: 2px 8px 2px 4px;
  backdrop-filter: blur(8px);
  background: #202028;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  right: 10px;
  top: -10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
}

.draw_button {
  min-height: 56px;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  border-radius: 8px;
}

.draw_quest_item_icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &_blue {
    border-color: #3957aa;
  }

  &_green {
    border-color: #089408;
  }
}
