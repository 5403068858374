.popular_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border-radius: 8px;
  position: relative;
}

.popular_item {
  width: calc(50% - 4px);
  border-radius: 8px;
  background: #1a1a1a;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  height: 100px;

  &_purple {
    background: linear-gradient(
        180deg,
        rgba(136, 71, 255, 0.3) 0%,
        rgba(170, 133, 255, 0.3) 100%
      ),
      radial-gradient(
        270.88% 100% at 100% 50%,
        rgba(136, 71, 255, 0.48) 0%,
        rgba(136, 71, 255, 0) 100%
      );
  }

  &_blue {
    background: linear-gradient(
        180deg,
        rgba(75, 105, 255, 0.3) 0%,
        rgba(117, 150, 255, 0.3) 100%
      ),
      radial-gradient(
        270.88% 100% at 100% 50%,
        rgba(75, 105, 255, 0.48) 0%,
        rgba(75, 105, 255, 0) 100%
      );
  }

  &_red {
    background: linear-gradient(
        180deg,
        rgba(235, 75, 75, 0.3) 0%,
        rgba(245, 116, 116, 0.3) 100%
      ),
      radial-gradient(
        270.88% 100% at 100% 50%,
        rgba(235, 75, 75, 0.48) 0%,
        rgba(235, 75, 75, 0) 100%
      );
  }

  &_yellow {
    background: linear-gradient(
        180deg,
        rgba(255, 198, 0, 0.3) 0%,
        rgba(255, 229, 27, 0.3) 100%
      ),
      radial-gradient(
        270.88% 100% at 100% 50%,
        rgba(255, 198, 0, 0.48) 0%,
        rgba(255, 198, 0, 0) 100%
      );
  }
}

.popular_image {
  position: absolute;
  right: 0;
  top: 0;
}

.popular_name {
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  color: #ffffff;
}

.popular_value {
  background-color: rgba(54, 55, 68, 0.32);
  border-radius: 4px;
  padding: 4px 8px;
  backdrop-filter: blur(8px);
  gap: 6px;
  display: flex;
  align-items: center;
}
