.giveaways_list1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
}

.giveaways_item_modal1 {
  width: 100% !important;
}
