.wheel_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  backdrop-filter: blur(8px);
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    align-items: flex-end;
  }
}

.wheel_modal_body {
  width: 100%;
  max-width: 400px;
  border-radius: 16px;
  padding: 24px;
  border: 2px solid #202028;
  background: #131318;

  @media (max-width: 767px) {
    border-radius: 16px 16px 0 0;
  }
}

.wheel_modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 83%;
    color: #ffffff;
  }
}

.wheel_modal_description {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #b3b5c6;
  margin-bottom: 24px;
}

.wheel_modal_prize {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.wheel_modal_btn {
  border-radius: 8px !important;
  margin-bottom: 24px;
  justify-content: center !important;
}

.wheel_modal_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #b3b5c6;
}
