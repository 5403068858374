.lucky_modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.lucky_modal_title {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 117%;
  color: #ffb800;
}

.demo_modal_prize {
  position: relative;
  background: linear-gradient(
      180deg,
      rgba(235, 75, 75, 0.3) 0%,
      rgba(245, 116, 116, 0.3) 100%
    ),
    radial-gradient(
      50% 100% at 43.55% 0%,
      rgba(235, 75, 75, 0.48) 0%,
      rgba(235, 75, 75, 0) 100%
    );
  border-radius: 8px;
  padding: 4px;
  height: 164px;
  display: flex;
  flex-direction: column;
}

.demo_modal_prize_image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}

.demo_modal_prize_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.demo_modal_prize_price,
.demo_modal_prize_percent {
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
  color: #ffffff;
  padding: 4px 4px 2px;
  background: rgba(19, 19, 24, 0.5);
  border-radius: 4px;
}

.demo_modal_prize_info {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.demo_modal_prize_tag {
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: #b3b5c6;
  margin-bottom: 8px;
}

.demo_modal_prize_name {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;

  span {
    color: #ffb800;
  }
}

.demo_modal_buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.lucky_modal_button {
  width: 100%;
}

.lucky_modal_button_dark {
  width: calc(50% - 6px);
}
