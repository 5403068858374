.modal1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  backdrop-filter: blur(8px);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding-top: 24px;

  @media (max-width: 767px) {
    align-items: flex-end;
  }
}

.modal1_body {
  width: 100%;
  max-width: 400px;
  border-radius: 16px;
  padding: 24px 16px;
  border: 2px solid #202028;
  background: #131318;
  max-height: calc(100dvh - 4px);

  @media (max-width: 767px) {
    border-radius: 16px 16px 0 0;
    animation: slideUp 0.5s ease-in-out forwards;
    overflow-y: auto;
    max-width: 520px;

    &.closing {
      animation: slideDown 0.5s ease-in-out forwards;
    }
  }

  @media (min-width: 768px) {
    animation: fadeIn 0.5s ease-in-out forwards;

    &.closing {
      animation: fadeOut 0.5s ease-in-out forwards;
    }
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.modal1_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 83%;
    color: #ffffff;
  }

  &.align_center {
    flex-direction: column;

    h2 {
      text-align: center;
      margin-bottom: 12px;
    }

    .modal_close {
      align-self: flex-end;
      margin-top: -40px;
      z-index: 10;
    }
  }
}

.modal1_close,
.modal1_close_only {
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  &:hover {
    opacity: 0.8;
  }
}

.modal1_close_only {
  margin-left: auto;
  margin-bottom: 16px;
}

.modal1_content {
  width: 100%;
  // max-height: calc(100dvh - 112px);
  // overflow-y: auto;
}
